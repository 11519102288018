<template>
  <md-card-actions v-show="total > 0" md-alignment="space-between">
    <div class="total-page">
      <p class="card-category">
        {{$I18n("total" )}} : {{total}} 
      </p>
    </div>
    <pagination :total="total" v-model="page_no" :per-page="pageSize" class="pagination-no-border right-button" type= "info"/>
  </md-card-actions>
</template>
<script>
import { Pagination } from "@/components";
export default {
  name: "table-page",
  components:{
    Pagination,
  },
  props: {
    total : {
      type: Number,
      default: 0,
    },
    pageSize : {
      type: Number,
      default: 10,
    },
    value : {
      type: Number,
      default: 1,
    },
  },
  data(){
    return {
      page_no : this.value,
    }
  },
  watch:{
    page_no(newValue){
      this.$emit('input',newValue)
    },
    value(newValue, oldValue){
      if(this.page_no !== newValue && newValue !== oldValue){
        this.page_no = newValue
      }
    },
  },
  methods:{ },
};
</script>