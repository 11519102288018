// =========================================================
// * Vue Material Dashboard PRO - v1.5.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";
import Notifications from './components/NotificationPlugin'
import Common from './components/Common'
import App from "./App.vue";
import Chartist from "chartist";

import routes from "./routes/routes";

import VueI18n from "vue-i18n";

import translate from "./common/lang/translate";
import {api, api_datacenter_url} from "@/common/interface_api/main.js";

import {DatePicker, Transfer} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '/public/css/element-variables.scss'
import "/public/css/extracss.scss"
import Param from "/public/ucfg/param.js";

Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(Notifications);
Vue.use(DatePicker);
Vue.use(Transfer);
Vue.use(Common);
Vue.use(VueI18n) // 通过插件的形式挂载

const i18n = new VueI18n({
    locale: 'zh-CN',    // 语言标识
    //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
      'zh-CN': translate,   // 中文语言包
    }
})

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  scrollBehavior: (to) => {
    if(!to.query.lang){
      const userLang = navigator.language || navigator.userLanguage;
      router.push({
        path: to.path,
        query:{
          ...to.query,
          lang: to.path === "/authorize"?"en" :userLang.includes("zh") ?"zh" :"en",
          // lang:"en",
        }
      })
    }
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active",
});

// global library setup
Vue.prototype.$Chartist = Chartist;
// 参数
Vue.prototype.$Param = {...Param};
// I18N 国家化
Vue.prototype.locale = "zh-CN"
Vue.prototype.$I18n = function(key){
  var lang = "zh"
  if (router.history.current.query.lang === "en"){
    lang = "en"
  }
  // console.log(window.location.href)
  // console.log(key);
  // console.log(lang)
  let res = i18n.t(`${lang}.${key}`);
  if (arguments.length === 1){
    return res;
  } else if(arguments.length > 1){
    for(let i = 1; i < arguments.length; i++){
      res = res.replace(`%${i}%`, arguments[i]);
    }
    return res;
  } else {
    return "";
  }
}
Vue.prototype.$En = function(key){
  var lang = "en"
  let res = i18n.t(`${lang}.${key}`)
  if (arguments.length === 1){
    return res;
  } else if(arguments.length > 1){
    for(let i = 1; i < arguments.length; i++){
      res = res.replace(`%${i}%`, arguments[i]);
    }
    return res;
  } else {
    return "";
  }
}
// API
Vue.prototype.$api = {
  __isSendingRequest__ : 0, // 大于0的时候开启弹窗
}
for (let key in api) {
  if(key.indexOf("__") == -1){
    Vue.prototype.$api[key] = function(data){
      Vue.prototype.$api.__isSendingRequest__ = Vue.prototype.$api.__isSendingRequest__ + 1
      return api[key](data)
        .then(res => {
          Vue.prototype.$api.__isSendingRequest__ = Vue.prototype.$api.__isSendingRequest__ - 1
          return res
        })
        .catch (err => {
          Vue.prototype.$api.__isSendingRequest__ = Vue.prototype.$api.__isSendingRequest__ - 1
          if(err.code === 401){
            if(!key.includes("other_api_")){
              localStorage.acc_token = "";
              router.push({
                path :"/login",
                query:router.history.current.query,
              }).catch(() => {});
            }else {
              Vue.prototype.$errorMsgIsShowing = true;
              setTimeout(() => {
                Vue.prototype.$errorMsgIsShowing = false;
              },2000)
              Vue.prototype.userInfo = {};
            }
          }
          throw err
        })
    }
  } else {
    Vue.prototype.$api[key] = api[key]
  }
}
// 普通使用的弹窗
Vue.prototype.$errorMsgIsShowing = false;
Vue.prototype.$successMsg = function(msg, time) {
    if(this.$errorMsgIsShowing){
      return
    }
    this.$notify({
      message: msg,
      icon: 'check',
      horizontalAlign: "center",
      verticalAlign: "top",
      type: "success",
      timeout: !isNaN(time) ?time :2000,
    });
}
Vue.prototype.$genThen = function(res){ // 检查API请求回调的Code是否是200
  if(res.code != 200){
    throw new Error ("Request failed, CDOE: " + res.code)
  }
  return res;
}
Vue.prototype.$errorMsg = function(msg) {
    if(this.$errorMsgIsShowing){
      return
    }
    this.$errorMsgIsShowing = true
    setTimeout(()=>{
      this.$errorMsgIsShowing = false
    } ,1900)
    this.$notify({
      message: msg,
        icon: 'error',
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "danger",
        timeout: 2000,
      });
}
Vue.prototype.$genCatch = function(msg){ // 检查API请求回调的Code是否是200
  return err => {
    console.error(err)
    if(msg){
      this.$errorMsg(msg);
    }
    throw err
  }
}
// 检查API请求回调的Code是否是200 excCode: 例外错误代码,该code不报错继续走then (int Array)
Vue.prototype.$Then = function(thenFunc, excCode){ 
  return function(res){
    if(res && !isNaN(res.code) && res.code != 200 && ((!excCode) || !excCode.includes(res.code))){
      throw res
    }
    if(thenFunc){
      return thenFunc(res)
    }
    return res;
  }
}
// 检查API请求回调的Code是否是200
Vue.prototype.$Catch = function(msg, thenFunc){
  return err => {
    console.log("ERROR: ", err)
    if(msg){
      this.$errorMsg(msg);
    }
    if(thenFunc){
      thenFunc(err)
    }
  }
}



// 登录用户信息
Vue.prototype.$setUserInfo =  function(newInfo){
  Vue.prototype.UserInfo = newInfo
}
Vue.prototype.UserInfo = {}
router.beforeEach( (to,_,next) => {
  if(window.location.href.includes("sparshsecuritech.com")){
    document.title = "Indian P2P Server"
  } else {
    if(to.meta.title){
      document.title = Vue.prototype.$I18n(to.meta.title)
    }else{
      document.title = Vue.prototype.$I18n("umeye_platform");
    }
  }
  next();
})

/* eslint-disable no-new */
new Vue({
  el: "#app",
  // i18n,
  render: (h) => h(App),
  router,
  data(){
    return {
      state : {
        apiurl: "",
        toggle_datacenter: false,
        mobile: false,
      },
    }
  },
  methods:{
    $checkSize :  function(){
      this.state.mobile = window.innerWidth < 599
    }
  },
  watch : {
    "state.apiurl" : {
      handler(newValue){
        api_datacenter_url.setURL(newValue);
      }
    },
  },
  created(){
    this.$checkSize();
    window.addEventListener('resize', this.$checkSize)
  },
  destroy(){
    window.addEventListener('resize', this.$checkSize, false)
  },
});
