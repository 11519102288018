export default {
	"getCompanyList": ["Array", true, [
		{key:"area"    , type:"string" },{key:"area_id"  , type:"number" },{key:"city"       , type:"string" },{key:"comp_short_name", type:"string" },
		{key:"comp_id" , type:"number" },{key:"comp_key" , type:"string" },{key:"comp_name"  , type:"string" },{key:"comp_parent_id" , type:"number" },
		{key:"country" , type:"string" },{key:"comp_addr", type:"string" },{key:"comp_top_id", type:"number" },{key:"comp_person"    , type:"string" },
		{key:"dk"      , type:"string" },{key:"dk_limit" , type:"number" },{key:"gmt_create" , type:"number" },{key:"gmt_update"     , type:"number" },
		{key:"province", type:"string" },{key:"sys"      , type:"string" },
		//过时参数
		{key:"latitude"  , type:"old" }, {key:"longitude", type:"old" }, {key:"comp_phone_area", type:"old" },
		{key:"comp_phone", type:"old" }, {key:"sort"     , type:"old" },
	]],
	"getMenu": ["Array", false, [
		{key:"menu_id", type:"number" },{key:"menu_key", type:"string" },{key:"menu_name", type:"string" },{key:"menu_cn_name", type:"string" },
		// 子菜单
		{key:"sub_menus", type: function(sub_menus){
			if(!sub_menus){
				return false;
			}
			return !sub_menus.every(sub => {
				return typeof sub.menu_id     === "number"
					&& typeof sub.menu_key    === "string"
					&& typeof sub.menu_name   === "string"
					&& typeof sub.menu_cn_name=== "string"
			})
		}}
	]],
	"addGood" : [],
	"editGood" : [],
	"delGood" : [],
	"addCloudOssInfo" : [],
	"editCloudOssInfo" : [],
	"delCloudOssInfo" : [],
	"addBaseApp" : [],
	"editBaseApp" : [],
	"addGoodClass" : [],
	"editGoodClass" : [],
	"delGoodClass" : [],
	"delCompany" : [],
	"editCompany" : [],
	"addCompany" : [],
	"getUserList" : [],
	"addMenu" : [],
	"editMenu" : [],
	"deleteMenu" : [],
	"getRole" : ["Array", false, [
		{key:"id", type:"number"},{key:"name", type:"string"},{key:"state", type:"number"},{key:"gmt_create", type:"number"},
	]],
	"addRole" : [],
	"delRole" : [],
	"getServerList" : [],
	"getApp" : [],
	"getAppDetailId" : [],
	"setAppPaymentRela" : [],
	"setAppProduct" : [],
	"getCloudOssInfo" : ["Array", false, [
		{key:"platform_id"    , type:"string" },{key:"platform_name"      , type:"string" },{key:"type_id"    , type:"number" },{key:"zone", type:"string" },
		{key:"platform_port"  , type:"string" },{key:"platform_app_key"   , type:"string" },{key:"comp_id"    , type:"number" },{key:"live", type:"number" },
		{key:"platform_bucket", type:"string" },{key:"platform_app_secret", type:"string" },{key:"id"         , type:"number" },
		{key:"create_time"    , type:"string" },{key:"platform_end_point" , type:"string" },{key:"support_img", type:"number" },
	]],
	"setCloudOssSet" : [],
	"getGoodClass" : [],
	"getGood" : [],
	"getPaymentSet" : ["Array", false, [
		{key:"platform_public_key" , type:"string" },{key:"remark"  , type:"string" },{key:"platform_app_id"    , type:"string" },
		{key:"platform_private_key", type:"string" },{key:"env_type", type:"number" },{key:"platform_notify_url", type:"string" },
		{key:"platform_return_url" , type:"string" },{key:"comp_id" , type:"string" },{key:"platform_type"      , type:"number" },
		{key:"id", type:"string" },
	]],
	"getBuyList" : [],
	"editRole" : [],
	"uploadIotOrder" : [],
	"getIotOrderXlsx" : [],
	"authServer" : [],
	"appVerQuery" : [],
	"addAppVer" : [],
	"editAppVer" : [],
	"delAppVer" : [],
	"appUrlQuery" : [],
	"addAppUrl" : [],
	"editAppUrl" : [],
	"getOrderStatistical" : [],
	"getOrderaCompStatistical" : [],
	"addEmailPlatform" : [],
	"editEmailPlatform" : [],
	"delEmailPlatform" : [],
	"getEmailPlatform" : [],
	"getUserCloudInfo" : [],
	"uploadDevOrder" : [],
	"getDevListXlsx" : [],
	"getOtherPlatform" : [],
	"addEmailTemplate" : [],
	"editEmailTemplate" : [],
	"delEmailTemplate" : [],
	"getEmailTemplate" : [],
	"setEmailAppBind" : [],
	"getEmailAppBind" : [],
	"addSmsPlatform" : [],
	"editSmsPlatform" : [],
	"delSmsPlatform" : [],
	"getSmsPlatform" : [],
	"addSmsTemplate" : [],
	"editSmsTemplate" : [],
	"delSmsTemplate" : [],
	"getSmsTemplate" : [],
	"setSmsAppBind" : [],
	"getSmsAppBind" : [],
	"editMsgPlatform" : [],
	"getMsgPlatform" : [],
	"editMsgTemplate" : [],
	"delMsgTemplate" : [],
	"getMsgTemplate" : [],
	"setMsgAppBind" : [],
	"getMsgAppBind" : [],
	// "getEmailRecord" : [],
	// "getSmsRecord" : [],
	// "getMsgRecord" : [],
	"getPushRecord": [],
	"getOtherPlatformInfo" : [],
	"addOtherPlatformInfo" : [],
	"editOtherPlatformInfo" : [],
	"delOtherPlatformInfo" : [],
	"bindAppOtherPlatform" : [],
	"getAppOtherPlatformBind" : [],
	"getIotOrder" : [],
	"addAppDetail" : [],
	"editAppDetail" : [],
	"queryAppBase" : ["Array", true, [
		{key:"id"       , type:"number" },{key:"name", type:"string" },{key:"comp_id", type:"number" },{key:"gmt_create", type:"number" },
		{key:"comp_name", type:"string" },{key:"type", type:"number" },
	]],
	"queryAppDetail" : [],
	"queryIotReg" : ["Array", true, []],
	"getClientStatis" : [],
	"unbindCS" : [],
	"addInfoEmail" : [],
	"editInfoEmail" : [],
	"delInfoEmail" : [],
	"queryInfoEmail" : [],
	"queryDevRegAna" : [],
	"getSysList" : [],
	"getProduct" : ["Array", true, [
		{key:"id"  , type:"number" },{key:"comp_id"      , type:"number" },{key:"node_type", type:"number" },{key:"create_time"     , type:"string" },
		{key:"pk"  , type:"string" },{key:"comp_name"    , type:"string" },{key:"img"      , type:"string" },{key:"update_time"     , type:"string" },
		{key:"name", type:"string" },{key:"category"     , type:"number" },{key:"remark"   , type:"string" },{key:"category_cn_name", type:"string" },
		{key:"sys" , type:"string" },{key:"category_name", type:"string" },{key:"attr"     , type:"jsonString"},
	]],
	"addProduct" : [],
	"editProduct" : [],
	"getProductVer" : [],
	"addProductVer" : [],
	"setProductVer" : [],
	"getSerZoneBind" : [],
	"addSerZoneBind" : [],
	"editSerZoneBind" : [],
	"delSerZoneBind" : [],
	"getRoleDetail" : [],
	// "getRoleList" : [],
	"getProductType" : [],
	"getFeedbackList" : [],
	"__getFeedbackList" : [],
	"getFeedbackDetail" : [],
	"__getFeedbackDetail" : [],
	"getFeedbackDevice" : [],
	"modifyFeedback" : [],
	"modifyFeedbackRemarkAndState" : [],
	"addNewFeedbackMsg" : [],
	"getDeviceState" : [],
	"buildXls" : [],
	"getDevDup" : [],
	"setDevDup" : [],
	"delService" : [],
	"getBalance" : ["Json", false, [{key:"money",type: "string"},{key: "money_type", type: "number"}]],
	"setBalance" : [],
	"pushPayList" : [],
	"getOrderInfo" : [],
	"setPaymentOrder" : [],
	"getUserFlow" : [],
	"getDevStateXls" : [],
	"getDevStateExport" : [],
	"deleteProductVer" : [],
	"getWolState" : [],
	"getActCode" : [],
	"getActCodeBatch" : [],
	"addActCodeBatch" : [],
	"editActCodeBatch" : [],
	"getFeedbackBot" : [],
	"addFeedbackBot" : [],
	"setFeedbackBot" : [],
	"addFeedbackBotCate" : [],
	"getFeedbackBotCate" : [],
	"setFeedbackBotCate" : [],
	"delFeedbackBotCate" : [],
	"getFeedbackBotWiki" : [],
	"addFeedbackBotWiki" : [],
	"setFeedbackBotWiki" : [],
	"delFeedbackBotWiki" : [],
	"goodPull" : [],
	"getIccIdList" : [],
	"importIccId" : [],
	"getImportIccInfo" : [],
	"exportDevice" : [],
	"getExportDevice" : [],
	"resetSrver" : [],
	"getFeedbackPush" : [],
	"setFeedbackPush" : [],
	"actCode" : [],
	"other_api_authCloud" : [],
	"other_api_authLogin" : [],
	"addImportOrder" : [],
	"getImportOrder" : [],
	"getLiveOrder" : [],
	"other_api_liveAuth" : [],
	"other_api_liveVideo" : [],
	"other_api_backLiveVideo" : [],
	"other_api_liveBeat" : [],
}