import { dataToSendData } from "@/common/interface_api/check_send.js";
export default function(api){
    api.__createBearerApi(["getLogList", "KLS-日志查询"], "/v2/kls/admin/log/list", function(data){
        return dataToSendData(data, {
            strKey : ["user_id", "uid"],
            numKey : ["type", "gmt_start", "gmt_end", "page_size", "page_no"],
        });
    });
    api.__createBearerApi(["getDevbindList", "KLS-配网查询"], "/v2/umh/admin/devbind/list", function(data){
        return dataToSendData(data, {
            strKey : ["tag"],
            numKey : [],
        });
    });
}