import {apiOBJ as api, api_datacenter_url, postData} from "@/common/interface_api/base.js";
import checResF from "@/common/interface_api/check_result.js";
import userApiF from "@/common/interface_api/api_user.js";
import payApiF from "@/common/interface_api/api_pay.js";
import mallApiF from "@/common/interface_api/api_mall.js";
import ossApiF from "@/common/interface_api/api_oss.js";
import pushApiF from "@/common/interface_api/api_push.js";
import iotApiF from "@/common/interface_api/api_iot.js";
import otaApiF from "@/common/interface_api/api_ota.js";
import umhApiF from "@/common/interface_api/api_umh.js";
import klsApiF from "@/common/interface_api/api_kls.js";
import discoveryApiF from "@/common/interface_api/api_discovery.js";

import { dataToSendData } from "@/common/interface_api/check_send.js";

userApiF(api); // api/*/user
payApiF (api); // api/*/pay
mallApiF(api); // api/*/mall
ossApiF (api); // api/*/oss
pushApiF(api); // api/*/push
iotApiF (api); // api/*/iot
otaApiF (api); // api/*/ota
umhApiF (api); // api/*/umh
klsApiF (api); // api/*/kls
discoveryApiF(api); // api/*/discovery

// AUTH
    api.__createApiByAppId_4(["other_api_authCloud", "AUTH-云授权"], "/auth/cloud" ,function(data){
        return dataToSendData(data, {
            strKey : ["key","client_id"],
            numKey : ["type", "client_type"],
        });
    });
    api.__createApiByAppId(["other_api_liveAuth", "AUTH-云授权"], "/auth/cloud" ,function(data){
        return dataToSendData(data, {
            strKey : ["key", "client_id"],
            numKey : ["type", "client_type"],
        });
    });
// AUTH
// SRV
    // 删除产品版本
    api.__createApiByAppId(["resetSrver", "SRV-删除产品版本"], "/srv/ctrl" ,function(data){
        return dataToSendData(data, {
            strKey : ["srv_addr"],
            numKey : ["srv_type","cmd"],
        });
    })
// SRV

checResF(api); // 校验返回的数据类型是否合法(或缺失)必须放最后
export {api, postData, api_datacenter_url};