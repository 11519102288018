<template>
  <div class="diy-login-box">
    <div class="left-box">
      <img src="/img/w_logo.png" width="50">
      <p>{{$I18n("welcome_1")}}</p>
      <p>{{$I18n("welcome_2")}}</p>
    </div>
    <md-card class="md-card-login" :class="{ 'md-card-hidden': cardHidden }" >
      <!-- <md-card-header :class="getClass(headerColor)">
        <slot name="title"></slot>
        <div class="social-line">
          <slot name="buttons"></slot>
        </div>
      </md-card-header> -->

      <md-card-content >
        <h3>{{$I18n('use_login')}}</h3>
        <slot name="description"></slot>
        <slot name="inputs"></slot>
      </md-card-content>

      <md-card-actions>
        <slot name="footer"></slot>
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
export default {
  name: "login-card",
  props: {
    headerColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cardHidden: true,
    };
  },
  beforeMount() {
    setTimeout(this.showCard, 400);
  },
  methods: {
    showCard: function () {
      this.cardHidden = false;
    },
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + "";
    },
  },
};
</script>

<style lang="css">
  .md-card-login{
    border-radius: 0;
    box-shadow: none;
    max-width: none !important;
    justify-content: center;
    padding-bottom: 110px;
  }
  h3{
    font-weight: 600;
  }
  .diy-login-box{
    display: flex;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    border-radius: 6px;
    overflow: hidden;
    max-width: 900px;
    height: 60vh;
    max-height: 540px;
    min-height: 470px;
  }
  .left-box p{
    margin: 0 0 14px 0;
    font-size: 18px;
  }
  .left-box{
    min-width: 260px;
    color: #ffffff;
    /*color: #000000;*/
    display: flex;
    font-size: 14px;
    background-color: #00bcd4;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 32px;
  }
  @media screen and (max-width: 600px){
    .diy-login-box{
      flex-direction: column;
    }
    .left-box {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
</style>

<!-- <div class="card card-login card-hidden">
  <div class="card-header card-header-rose text-center">
    <h4 class="card-title">Log in</h4>
    <div class="social-line">
      <a href="#pablo" class="btn btn-just-icon btn-link btn-white">
        <i class="fa fa-facebook-square"></i>
      </a>
      <a href="#pablo" class="btn btn-just-icon btn-link btn-white">
        <i class="fa fa-twitter"></i>
      </a>
      <a href="#pablo" class="btn btn-just-icon btn-link btn-white">
        <i class="fa fa-google-plus"></i>
      </a>
    </div>
  </div>
  <div class="card-body ">
    <p class="card-description text-center">Or Be Classical</p>
    <span class="bmd-form-group">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="material-icons">face</i>
          </span>
        </div>
        <input type="text" class="form-control" placeholder="First Name...">
      </div>
    </span>
    <span class="bmd-form-group">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="material-icons">email</i>
          </span>
        </div>
        <input type="email" class="form-control" placeholder="Email...">
      </div>
    </span>
    <span class="bmd-form-group">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="material-icons">lock_outline</i>
          </span>
        </div>
        <input type="password" class="form-control" placeholder="Password...">
      </div>
    </span>
  </div>
  <div class="card-footer justify-content-center">
    <a href="#pablo" class="btn btn-rose btn-link btn-lg">Lets Go</a>
  </div>
</div> -->
