import { dataToSendData } from "@/common/interface_api/check_send.js";
export default function(api){
    api.__createBearerApi(["addCloudOssInfo", "OSS-添加云存储信息"], "/v2/oss/admin/platform/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name", "zone", "app_key", "app_secret", "end_point", 
                "bucket", "sys", "store_path"],
            numKey : ["mode", "comp_id", "type", "port","support_img", "state"],
        });
        return sendData
    });

    api.__createBearerApi(["editCloudOssInfo", "OSS-修改云存储信息"], "/v2/oss/admin/platform/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name", "zone", "app_key", "app_secret", "end_point", 
                "bucket", "sys", "store_path"],
            numKey : ["id", "mode", "comp_id", "type", "port","support_img", "state"],
        });
        return sendData
    });

    api.__createBearerApi(["delCloudOssInfo", "OSS-删除云存储信息"], "/v2/oss/admin/platform/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
        return sendData
    });

    api.__createBearerApi(["getCloudOssInfo", "OSS-获取云存储信息"], "/v2/oss/admin/platform/list" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["sys","type"],
            numKey : ["mode","page_no", "page_size"],
        });
        return sendData
    });
    
    api.__createApi(["setCloudOssSet", "OSS-APP的云存储相关设置"], "/oss/appid/set" , function(data){
        return data
    });
    api.__createApiByAppId_1(["other_api_liveVideo", "OSS-线上URL"], "/oss/live/url" ,function(data){
        return dataToSendData(data, {
            strKey : ["uid", "user_id","user_password","acc_token"],
            numKey : ["ch_no"],
        });
    });
    api.__createApiByAppId_1(["other_api_backLiveVideo", "OSS-回放URL"], "/oss/playback/url" ,function(data){
        return dataToSendData(data, {
            strKey : ["uid", "user_id","user_password","acc_token"],
            numKey : ["ch_no", "gmt_start", "gmt_end"],
        });
    });
    api.__createApiByAppId_1(["other_api_liveBeat", "OSS-在线沟通心跳"], "/oss/live/beat" ,function(data){
        return dataToSendData(data, {
            strKey : ["acc_token"],
            numKey : ["id"],
        });
    });
}