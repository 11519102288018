<template>
  <div>
    <router-view></router-view>
    <modal :loading="true" v-show="nowLoading">
      <template slot="loading">
        <div>
          <i class="iconfont modal-load-icon" :style="{transform: `rotate(${rotatedeg}deg)`}">&#xe61e;</i>
        </div>
        <div :style="{'display':'none'}">loading</div>
      </template>
    </modal>
  </div>
</template>

<script>
import { Modal } from '@/components';
import "/public/font/iconfont.css"
export default {
  components: {
    Modal,
  },
  data() {
    return {
      rotatedeg: 0,
      loadingInterval : 0,
      nowLoading :false,
      interval:0,
    }
  },
  watch:{
    nowLoading(to, back){
      if(to != back){
        if(to){ // 开启
          this.loadingInterval =  setInterval(() => {
            this.rotatedeg = this.rotatedeg + 10;
            if(this.rotatedeg > 360){
              this.rotatedeg = this.rotatedeg - 360
            }
          } , 30)
        } else { // 关闭
          clearInterval(this.loadingInterval)
        }
      }
    },
  },
  created () {
    this.$api.__isSendingRequest__ = 0
    this.interval = setInterval(() => {
      if(this.$api.__isSendingRequest__ < 0){
        this.$api.__isSendingRequest__ = 0
      }
      this.nowLoading = this.$api.__isSendingRequest__ > 0
    }, 1000)
  },
  destroyed(){
    clearInterval(this.interval)
  }
};
</script>
<style lang="scss">
.modal-load-icon{
  color: #00bcd4 !important;
  font-size: 100px !important;
  display: inline-block;
  line-height: 100px;
}
</style>
