// 校验JSON变化的
import interfaceCheckJSON from "@/common/interface_api/custom_interfaceCheckJSON.js";
const serviceMode =  window.appConfig.log ?"test"  :"offc";
// 对返回结果进行校验
/*
    * 需要判断 实际和判断的参数key有哪些增加 or 减少
    * checkParam => Array({key: KEY名, type: 判断类型, init: 防止参数错误})
    * KEY 对应的key
    * type 判断类型 KEY的参数类型 ,包括 
    * "string" / "number"  / "stringJson"(是string的JSON或者数组,要增加一个JSON.parse)
    * [a, b, c] 仅仅只能值为a, b, c,
    * Function (根据函数来判断, true为合格 false为不合格)
    *
    * init 如果参数不符合, 就调用 init 的函数(或者值)
    *
*/
var checkResult = function(dataType , hasCount, checkParam, otherMsg){ // 参数传进来的Key | data类型(1:Array, 2:Json, 3: None*不检查) | 是否有 count | 有错误的额外显示
    if(!window.appConfig.checkApiRes){ /* || serviceMode === "offc"*/
        return function(res){
            return res
        }
    }
    function check (resData) {
        let res = {...resData}
        let errorKeyArray = checkParam.filter(keyItem => {
            let keyValue = res[keyItem.key];

            let isError = false;
            try {
                if(keyItem.key in res){
                    // 判定
                    if(keyItem.type instanceof Array){
                        isError = ! keyItem.type.includes(keyValue);
                    } else if (keyItem.type instanceof Function) {
                        isError = keyItem.type(keyValue, res)
                    } else {
                        switch(keyItem.type){
                            case "string": // 字符串
                                isError = typeof keyValue !== "string"
                                break

                            case "number": // 数字
                                isError = isNaN(keyValue);
                                break

                            case "json" : // JSON
                                isError = typeof keyValue !== "object"
                                break

                            case "jsonString": //是JSON的字符串
                                isError = typeof keyValue !== "string" 
                                if(!isError){ // 若是 string 则继续校验
                                    try{
                                        JSON.parse(keyValue);
                                    } catch  { // 无法转换成JSON 则 报错
                                        isError = true;
                                    }
                                }
                                break
                            case "old": // 过时参数
                                isError = false;
                                break
                            default:
                                isError = false;
                                break;
                        }
                    }
                }else if (keyItem.required) {
                    isError = true;
                }
                // 若错误且有INIT则进行处理
                if(isError && keyItem.init){ 
                    if(typeof keyItem.init === 'function'){
                        res[keyItem.key] = keyItem.init();
                    } else {
                        res[keyItem.key] = keyItem.init;
                    }
                    isError = false
                }
            } catch (e) {
                console.log(e);
            }
            return isError
        }).map(keyItem => keyItem.key);
        let checkKeys = checkParam.map(keyItem => {
            return keyItem.key;
        })
        let missingKey = Object.keys(res).filter(key => {
            return !checkKeys.includes(key)
        })
        if(errorKeyArray.length > 0 || missingKey.length > 0){
            if(serviceMode === "test"){
                console.log( 
                    `%c${otherMsg?otherMsg+"\n" :""}`+
                    (errorKeyArray.length > 0?`===> 参数错误表\n===> ${errorKeyArray.join(" , ")}\n` :"")+
                    (missingKey.length    > 0?`===> 未校验参数\n===> ${missingKey.join(" , ")}` :""), 
                    errorKeyArray.length > 0 ?"color : red" :"color : blue", // 字体颜色
                    res // 原始参数
                )
            }
        }
        //正常返回 参数本身
        return res
    }
    return function(resultData){        // API请求后传入的参数
        let params = resultData.data;
        let resParam = null
        // 判断CODE是多少
        if(serviceMode === "test"){
            if(resultData.code !== 200){
                console.warn(`%c===> Code: ${resultData.code}`, "color: red")
            }
            // 是否有 data.count 参数
            if(hasCount && !resultData.count){
                console.warn("%c===> Count 丢失", "color: red")
            }
        }
        if(dataType === "Array"){
            if(!params){
                resParam = [];
            } else {
                resParam = params.map(check);
            }
        } else if (dataType === "Json"){
            resParam = check(params);
        } else { // 暂无校验 方便添加注释用
            if(serviceMode === "test"){
                console.warn(otherMsg)
                if(params instanceof Array){
                    console.warn("参数为Array : ", params[0])
                } else {
                    console.warn("参数为Json : ", params)
                }
                if(resultData.count){
                    console.warn("存在 count ")
                } else {
                    console.warn("不存在 count ")                
                }
            }
            resParam = params;
        }
        return {
            ...resultData,
            data: resParam, // 处理后的Data
        }
    }
}
export default function(apiOBJ){
    var apiSetCheck = function(key, dataType , hasCount, checkParam){
        if(!apiOBJ[key]){
            // console.error("API: ",key,"不存在!!!")
            return
        }
        var oldFunc = apiOBJ[key]
        apiOBJ[key] = function(){
            return oldFunc(...arguments).then(checkResult(dataType , hasCount, checkParam, `===> KEY: ${key}`));
        };
    }

    for(let key in interfaceCheckJSON){
        apiSetCheck(key, ...interfaceCheckJSON[key]);
    }
}