import { dataToSendData } from "@/common/interface_api/check_send.js";
export default function(api){
    api.__createApi(["addPayment", "PAY-添加支付配置"], "/pay/info/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["platform_app_id","comp_id","platform_public_key","platform_private_key","platform_return_url",  "remark"],
            numKey : ["platform_type", "env_type"],
        });
        sendData.id = data.id;
        return sendData;
    });

    api.__createApi(["editPayment", "PAY-修改支付配置"], "/pay/info/modify", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["platform_app_id","comp_id","platform_public_key","platform_private_key","platform_return_url",  "remark"],
            numKey : ["platform_type", "env_type"],
        });
        sendData.id = data.id;
        return sendData;
    });

    api.__createApi(["delPayment", "PAY-删除支付配置"], "/pay/info/delete", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["platform_app_id","comp_id","platform_public_key","platform_private_key","platform_return_url",  "remark"],
            numKey : ["platform_type", "env_type"],
        });
        sendData.id = data.id;
        return sendData;
    });

    api.__createApi(["setAppPaymentRela", "PAY-配置APP相关支付配置"], "/pay/appid/set" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id"],
            numKey : [],
        });
        sendData.ids = data.ids;
        return sendData
    });
    
    api.__createApi(["getPaymentSet", "PAY-查询APP"], "/pay/appid/query" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id"],
            numKey : ["type", "page_no", "page_size"],
        });
        return sendData
    });

    api.__createApiByAppId(["setPaymentOrder", "PAY-添加支付订单"], "/pay/order/add" ,function(data){
        return dataToSendData(data, {
            strKey : ["comp_price", "user_id", "pay_order_id", "order_key", "order_name", "order_fee", "timestamp", "uid", "order_notify_url", "ip_addr"],
            numKey : ["type",  "fee_type", "mode", "goods_type"],
        });
    })
}