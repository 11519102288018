import { JSEncrypt } from "jsencrypt"
const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDwg13VOsZIA3tzF1pIevDIbdUj"+
"7ob+lbki0XpHQ+OJR2kjAx0IVn0CfvVUoOzQ2uIgjNQSxcojaxYYIydtmeBmLcKK"+
"fnhEvGfIzUzlBUAyTSzEHhynlSJVmpeodY6pznnDOkCPOtREes7w+VN019l5FFCA"+
"DCqZLf6ZJxbi0N7TDwIDAQAB"

var dateYYYYMMDD = function(dateString) {
    var list = dateString.split("-")
    if (list[1].length == 1){
        list[1] = "0" + list[1]
    }
    if (list[2].length == 1){
        list[2] = "0" + list[2]
    }
    return list.join("-")
}
// 判断是否是数组
function isArray(obj) {
  return Object.prototype.toString.call(obj) === '[object Array]';
}

//判断是否是JSON(返回Boolean)
function isJson(param) {
    try {
        return (Object.prototype.toString.call(param) === "[object Object]")
    } catch(e) {
        console.log(param)
    }
    //console.log("不是json")
    return false;
}
// 保留2位小数
export function toDecimal(x) {   
    var f = parseFloat(x);   
    if (isNaN(f)) {   
        return;   
    }   
    f = Math.round(x*100)/100;   
    return f;   
}   
//返回一个obj的复制(返回obj)
export function copyObj (oldObj){
    var obj;
    if ((isJson(oldObj) && (obj = new Object())) || (isArray(oldObj) && (obj = new Array()))){
        if(oldObj.Copy){
            obj = oldObj.Copy()
        } else {
            for(var i in oldObj){
                obj[i] = (isJson(oldObj[i]) || isArray(oldObj[i]))
                    ? copyObj(oldObj[i])
                    : oldObj[i];
            }
        }
    }
    return obj;
}
// 获取用户的所在的地区的语言
export function getLanguage (){
    var currentLang;  
    currentLang = navigator.language;  
    if(!currentLang)
        currentLang = navigator.browserLanguage;
    if(currentLang == 'zh-CN' || currentLang === 'zh-cn'){  //中文 环境 
        currentLang = 'zh';
        // currentLang = 'en';
    }else{
        currentLang = 'en';     // 英文 环境
    }
    return currentLang;
}
//获取操作系统版本
export function detectOS() {
    var sUserAgent = navigator.userAgent;
    var isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows");
    var isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel");
    if (isMac) return "Mac";
    var isUnix = (navigator.platform == "X11") && !isWin && !isMac;
    if (isUnix) return "Unix";
    var isLinux = (String(navigator.platform).indexOf("Linux") > -1);
    if (isLinux) return "Linux";
    if (isWin) {
        var isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1;
        if (isWin2K) return "Win2000";
        var isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 || sUserAgent.indexOf("Windows XP") > -1;
        if (isWinXP) return "WinXP";
        var isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1;
        if (isWin2003) return "Win2003";
        var isWinVista= sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1;
        if (isWinVista) return "WinVista";
        var isWin7 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1;
        if (isWin7) return "Win7";
        var isWin10 = sUserAgent.indexOf("Windows NT 10") > -1 || sUserAgent.indexOf("Windows 10") > -1;
        if (isWin10) return "Win10";
    }
    return "other";
}

export function nPw (user_id , user_passwd){
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    var doBefore = user_id + "\r\n" + user_passwd + "\r\n" + parseInt(new Date().getTime()/1000);
    var pw = encrypt.encrypt(doBefore)
    return pw;
}


// n天前
export function nDaysAgo (n, startDate){
    var date = new Date(new Date().getTime() - 1000* 60* 60* 24* n);
    if(startDate) {
        date = new Date(new Date(startDate).getTime() - 1000* 60* 60* 24* n);
    }
    return dateYYYYMMDD(date.getFullYear() + "-" + (date.getMonth()+1 % 12) + "-" + date.getDate());
}
// n个月前 (的1号)
export function nMonthsAgo (n, startDate, noResetDay){
    if(startDate){
        if(noResetDay){
            return dateYYYYMMDD(
                new Date(
                    new Date(
                        new Date(new Date(startDate))
                    )
                    .setMonth(
                        (new Date(startDate)).getMonth() - n
                    )
                )
                .toLocaleDateString()
                .replace(/\//g,"-")
            )
        }
        return dateYYYYMMDD(
            new Date(
                new Date(
                    new Date(new Date(startDate)).setDate(1)
                )
                .setMonth(
                    (new Date(startDate)).getMonth() - n
                )
            )
            .toLocaleDateString()
            .replace(/\//g,"-")
        )
    }
    return dateYYYYMMDD(new Date(new Date(new Date().setDate(1)).setMonth((new Date()).getMonth() - n)).toLocaleDateString().replace(/\//g,"-"))
}
export function nDateRound (start, end, type){ // type = 1 :按1天跨度, type =2 按1个月跨度
    var result =[]
    if(type == 1){
        var n = (new Date(end) - new Date(start))/(1000*60*60*24)
        for(let i =0 ; i <= n; i++){
            result.push(nDaysAgo(0-i, start))
        }
    } else if (type ==2){
        for(let i =0 ; new Date(nMonthsAgo(0-i,start)) < new Date(nMonthsAgo(-1,end)) ; i++) {
            var list = nMonthsAgo(0-i,start).split("-")
            result.push(list[0]+"-"+list[1])
        }
    }
    return result
}
export function timestampToString (stamp) {
    var date = new Date(stamp);
    
    return date.getFullYear() + "-" + 
        (date.getMonth()+1<=9 ? "0": "") + (date.getMonth()+1) + "-" + 
        (date.getDate() <=9 ?"0" :"") + date.getDate() + " " + 
        (date.getHours() <= 9 ? "0" :  "") + date.getHours() + ":" + 
        (date.getMinutes() <= 9 ? "0" :"") + date.getMinutes() + ":" + 
        (date.getSeconds() <= 9 ? "0" :"") + date.getSeconds()
    // return dateYYYYMMDD(date.getFullYear() + "-" + (date.getMonth()+1 % 12) + "-" + date.getDate());
}
export function timestampToString1000 (stamp) {
    var date = new Date(stamp * 1000);
    
    return date.getFullYear() + "-" + 
        (date.getMonth()+1<=9 ? "0": "") + (date.getMonth()+1) + "-" + 
        (date.getDate() <=9 ?"0" :"") + date.getDate() + " " + 
        (date.getHours() <= 9 ? "0" :  "") + date.getHours() + ":" + 
        (date.getMinutes() <= 9 ? "0" :"") + date.getMinutes() + ":" + 
        (date.getSeconds() <= 9 ? "0" :"") + date.getSeconds()
    // return dateYYYYMMDD(date.getFullYear() + "-" + (date.getMonth()+1 % 12) + "-" + date.getDate());
}
export function stringToJSON(stringJSON, defualtJSON){
    try{
        return JSON.parse(stringJSON)
    } catch  {
        return defualtJSON ?defualtJSON :{}
    }
}

// 校验用 class  *** 返回true才表示报错 ***
export class FormCheckClass{
    constructor(checkJson){ // {'参数KEY': '参数类型'}  参数类型可以是 'string' [Array] function()
        if(!checkJson){
            return
        }
        this.__Check__ = {
            ...checkJson,
        }
        // this.__Key__ = Object.keys(this.__Check__)
        this.__Key__.forEach(key => {
            this[key] = false; // true : 建议失败
        })
    }
    get __Key__(){
        return Object.keys(this.__Check__)
    }
    get Error(){ // 返回一个Boolean [true: 存在不符合条件参数, false: 全部合格]
        return this.__Key__.map(key => {
            return this[key]
        }).some(value => value)
    }
    get ErrorList () { // 返回一个数组所有没通过校验有问题的key
        return this.__Key__.filter(key => {
            return this[key]
        })
    }
    Reset(){
        this.__Key__.forEach(key => {
            this[key] = false
        })
    }
    Check(verif_param, otherParam){ // 
        this.__Key__.forEach(key => {
            let value = verif_param[key];
            let check = this.__Check__[key]
            switch(typeof check){
                case "string":
                    if(!this["Check_" + check]){
                        console.log("该功能暂时没做:", check)
                    } else {
                        this[key] = this["Check_" + check](value)
                    }
                    break
                case "function":
                    this[key] = check(value, verif_param, otherParam)
                    break
                case "Object":
                    console.log("该功能暂时没做:", check)
                    break
            }
        })
        if(this.Error){
            console.error("错误 参数:", this.ErrorList)
        }
        return this.Error
    }
    Check_int (val){
        return isNaN(val) || val === ""
    }
    Check_number (val){
        return isNaN(val) || val === ""
    }
    Check_required (val){
        return !val
    }
}
// 表单 class  暂时只是用来 重置参数/检查参数是否全部合法 参数在 this.Form 里
/*
    * {
    * init     默认值
    * check    参数限制 [支持 int/num/required 以及自定义函数]
    * // sort     转化成数列时所处位置 [0~]
    * // disabled 可否修改 (true 为不可修改)
    * // require  是否必填 (true 为必填)
    * // type     展示类型 (input: 输入框/ select: 选择框/ **后续可能补充)
    * 
    *{
    *  label: "",
    *  key  : "",
    *  type : "",   // select|input|chips
    *  placeholder  : "",
    *  options : [],
    *  required: false,
    *  disabled: false,
    *  error   : false,
    *  hide    : false,
    *}
    * 
    * }
*/
export class FormClass{
    constructor(formInit, check){
        // 合并 内容格式为 [key] : {init:"..." , check:"..." ,}
        if(!check){
            let init = {};
            let check = {};
            for(var key in formInit){
                // 没有Check 的简单写法  [key] : {...init} ***Json的不能使用
                if(typeof formInit[key] !== 'object' || formInit[key] instanceof Array){
                    init[key] = formInit[key]
                } else {
                    // INIT
                    if(formInit[key].init === undefined){
                        init[key] = "";
                    }else{
                        init[key] = formInit[key].init;
                    }
                    // CHECK
                    if(formInit[key].check){
                        check[key] = formInit[key].check;
                    }
                }
                // 初始化
            }
            this.Form = {};
            this.__Init__ = {...init}
            for(let key in init){
                if(typeof init[key] === 'function'){
                    this.Form[key] = init[key]()
                } else {
                    this.Form[key] = init[key]
                }
            }
            this.Error = new FormCheckClass(check)
        } else { // 初始化数据 和 check 分开
            this.__Init__ = {...formInit}
            this.Form = {...formInit}
            this.Error = new FormCheckClass(check)
        }
        // for(let key in this.__Init__){
        //     this[key] = this.__Init__[key]
        // }
    }
    get ErrorList () { // 返回一个数组所有没通过校验有问题的key
        return this.Error.ErrorList;
    }
    SetInit(newInit, key){
        if(key){
            this.__Init__[key] = newInit;
        }else{
            for(let otherKey in newInit){
                this.__Init__[otherKey] = newInit[key]
            }
        }
        this.Reset();
    }
    Reset(){ // 重置回默认值
        for(let key in this.Form){
            this.Form[key] = null
        }
        for(let key in this.__Init__){
            if(typeof this.__Init__[key] === 'function'){
                this.Form[key] = this.__Init__[key]()
            } else {
                this.Form[key] = this.__Init__[key]
            }
        }
        this.Error.Reset();
    }
    Update(val){ // 批量更新内容(并重置错误列表)
        this.Form = {...val};
        this.Error.Reset();
    }
    ResetUpdate(val){
        this.Reset();
        for(let key in val){
            this.Form[key] = val[key];
        }
    }
    Check(otherParam){ // 检查表单合法性
        return this.Error.Check(this.Form, otherParam);
    }
}

//格式化json @zhangxh
export  function formatJsonForNotes(json, options) {
    var reg = null,
        formatted = '',
        pad = 0,
        PADDING = '  '; // （缩进）可以使用'\t'或不同数量的空格
    // 可选设置
    options = options || {};
    // 在 '{' or '[' follows ':'位置移除新行
    options.newlineAfterColonIfBeforeBraceOrBracket = (options.newlineAfterColonIfBeforeBraceOrBracket === true) ? true : false;
    // 在冒号后面加空格
    options.spaceAfterColon = (options.spaceAfterColon === false) ? false : true;
    // 开始格式化...
    if (typeof json !== 'string') {
        // 确保为JSON字符串
        json = JSON.stringify(json);
    } else {
        //已经是一个字符串，所以解析和重新字符串化以删除额外的空白
        json = JSON.parse(json);
        json = JSON.stringify(json);
    }
    // 在花括号前后添加换行
    reg = /([{}])/g;
    json = json.replace(reg, '\r\n$1\r\n');
    // 在方括号前后添加新行
    reg = /([[\]])/g;
    json = json.replace(reg, '\r\n$1\r\n');
    // 在逗号后添加新行
    reg = /(,)/g;
    json = json.replace(reg, '$1\r\n');
    // 删除多个换行
    reg = /(\r\n\r\n)/g;
    json = json.replace(reg, '\r\n');
    // 删除逗号前的换行
    reg = /\r\n,/g;
    json = json.replace(reg, ',');
    // 可选格式...
    if (!options.newlineAfterColonIfBeforeBraceOrBracket) {       
        reg = /:\r\n\{/g;
        json = json.replace(reg, ':{');
        reg = /:\r\n\[/g;
        json = json.replace(reg, ':[');
    }
    // if (options.spaceAfterColon) {        
    //     reg = /:/g;
    //     json = json.replace(reg, ': ');
    // }
    json.split('\r\n').forEach(function(node) {
        var i = 0,
            indent = 0,
            padding = '';
        if (node.match(/\{$/) || node.match(/\[$/)) {
            indent = 1;
        } else if (node.match(/\}/) || node.match(/\]/)) {
            if (pad !== 0) {
                pad -= 1;
            }
        } else {
            indent = 0;
        }
        for (i = 0; i < pad; i++) {
            padding += PADDING;
        }
        formatted += padding + node + '\r\n';
        pad += indent;
    });
    return formatted;
}
// 获取初始COMP
export function getInitComp(compList, userComp){
    let init_comp = "";
    if(compList.some(i => i.comp_id === userComp)){
        init_comp = userComp;
    } else if(compList.some(i => !i.comp_parent_id && !i.comp_top_id)){
        init_comp = compList.filter(i => !i.comp_parent_id && !i.comp_top_id)[0].comp_id;
    } else if(compList.length > 0){
        init_comp = compList[compList.length - 1].comp_id;
    }
    return init_comp;
}