<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div class="hide-tree" :class="showTree ? 'show-tree' : ''" @click="showSubTree">
        <md-icon v-if="children && children.length > 0">expand_more</md-icon>
      </div>
      <md-checkbox v-model="mainResultData" :value="mainValue">{{mainName}}</md-checkbox>
      
    </div>

    <div v-if="children && children.length > 0" ref="sub" class="tree-sub">
      <div class="tree-sub-item"> 
        <div v-for="item in children" :key="item.value">
          <md-checkbox v-model="resultData" :value="item.value">{{item.name}}</md-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "tree",
  props: {
    mainValue : {
      type: String,
      default: "",
    },
    mainName : {
      type: String,
      default: "",
    },
    children : {
      type : Array,
      default :  () => {
        return [];
      },
    },
    initData : {
      type : Array,
      default :  () => {
        return [];
      },
    }
  },
  created () {
    console.log("?")
    this.initData.forEach(item => {
      if (item == this.mainValue){
        this.mainResultData = [this.mainValue];
      } else if(this.children.indexOf(item) > -1){
        this.resultData.push(item)
      }
    })
  },
  data(){
    return {
      showTree : false,
      mainResultData : [],
      resultData: [],
    }
  },
  computed: {
    compData () {
      return [...this.mainResultData, ...this.resultData];
    }
  }, 
  watch : {
    compData(to){
      this.$emit("onchange", to)
    },
    mainResultData (to) {
      if(to.length > 0 && this.children.length > 0) {
        this.resultData = this.children.map(item => {
          return item.value;
        })
      } else if(to.length === 0 && this.resultData.length > 0) {
        this.resultData = [];
      }
    },
    resultData (to){
      if(to.length === this.children.length && this.mainResultData.length === 0) {
        this.mainResultData = [this.mainValue];
      } else if (to.length === 0 && this.mainResultData.length > 0) {
        this.mainResultData = [];
      }
    },
  },
  methods: {
    showSubTree() {
      this.showTree = !this.showTree;
      this.$refs.sub.style.height = this.showTree
        ?this.$refs.sub.childNodes[0].offsetHeight + "px"
        :"0";
    },
  },
};
</script>
<style lang="scss" scoped>
  .md-layout-item{
    padding:0;
  }
  // 树状 多选框
  .hide-tree {
    width: 32px;
    height: 52px;
    line-height: 52px;
    display: inline-block;
    float: left;
    text-align: center;
    transform: rotate( 360deg );
    transition: transform .5s;
  }
  .show-tree {
    transform: rotate( 180deg );
  }
  .tree-sub{
    overflow : hidden;
    height:0;
    transition: height .5s;
  }
  .tree-sub-item {
    margin-left: 60px;
  }
  .md-layout-item{
    text-align:left;
  }
</style>