import DropDown from "./components/Dropdown.vue";
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
// import CardFormItem from "./components/CardFormItem.vue";
// import ModalFormItem from "./components/ModalFormItem.vue";
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("drop-down", DropDown);
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
    // 额外添加
    // Vue.component("CardFormItem", CardFormItem);
    // Vue.component("ModalFormItem", ModalFormItem);
  },
};

export default GlobalComponents;
