import { dataToSendData } from "@/common/interface_api/check_send.js";
export default function(api){
    api.__createBearerApi(["getLicList", "UMH-获取LIC列表"], "/v2/umh/admin/liccode/list" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["lic_code", "grant_mac"],
            numKey : ["page_no", "page_size"],
        });
        return sendData;
    });
    api.__createBearerApi(["getAppUserList", "UMH-获取角色列表"], "/v2/umh/admin/user/list" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["user_id"],
            numKey : ["page_no", "page_size", "comp_id", "master_id"],
        });
        return sendData;
    });
    api.__createSimpleApi(["getAppUserProductList", "UMH-获取产品列表"], "/v2/umh/admin/product/list" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["user_id","uid"],
            numKey : ["page_no", "page_size", ],
        });
        return sendData;
    });
    api.__createBearerApi(["getShareProduct", "UMH-获取分享商品列表"], "/v2/umh/admin/product/share/list" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["user_id","node_id"],
            numKey : ["page_no", "page_size"],
        });
        return sendData;
    });
    api.__createBearerApi(["addShareProduct", "UMH-新增分享商品"], "/v2/umh/admin/product/share/add" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["to_userid","node_id"],
            numKey : [],
        });
        return sendData;
    });
    api.__createBearerApi(["delShareProduct", "UMH-删除分享列表"], "/v2/umh/admin/product/share/delete" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["id"],
            numKey : [],
        });
        return sendData;
    });
    api.__createBearerApi(["delAppUserProduct", "UMH-删除产品"], "/v2/umh/admin/product/delete" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["node_id"],
            numKey : [],
        });
        return sendData;
    });

    api.__createBearerApi(["addMsgCfg", "UMH-添加消息事件配置"], "/v2/umh/admin/msg/eventcfg/add" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["event_name", "lang_flag"],
            numKey : ["alarm_event"],
        });
        return sendData;
    });

    api.__createBearerApi(["editMsgCfg", "UMH-设置消息事件配置"], "/v2/umh/admin/msg/eventcfg/set" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["event_name", "lang_flag"],
            numKey : ["id", "alarm_event"],
        });
        return sendData;
    });
    api.__createBearerApi(["delMsgCfg", "UMH-删除消息事件配置"], "/v2/umh/admin/msg/eventcfg/del" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
        return sendData;
    });

    api.__createBearerApi(["getMsgCfg", "UMH-查询消息事件配置"], "/v2/umh/admin/msg/eventcfg/list" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["alarm_event","page_no", "page_size"],
        });
        return sendData;
    });

    api.__createBearerApi(["addMsgTemp", "UMH-添加消息事件模板"], "/v2/umh/admin/msg/template/add" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["lang_flag", "lang_name", "msg_format"],
            numKey : ["mode"],
        });
        return sendData;
    });

    api.__createBearerApi(["editMsgTemp", "UMH-设置消息事件模板"], "/v2/umh/admin/msg/template/set" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["lang_flag", "lang_name", "msg_format"],
            numKey : ["id", "mode"],
        });
        return sendData;
    });
    api.__createBearerApi(["delMsgTemp", "UMH-删除消息事件模板"], "/v2/umh/admin/msg/template/del" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
        return sendData;
    });

    api.__createBearerApi(["getMsgTemp", "UMH-查询消息事件模板"], "/v2/umh/admin/msg/template/list" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["page_no", "page_size"],
        });
        return sendData;
    });
}