// 请求数据参数类型校验
export function dataToSendData(data, keyObj){
    if(!keyObj || Object.keys(keyObj).length == 0){
        console.log("请输入keyObj, 不然不做处理");
        return data;
    }
    var sendData = {};
    if(keyObj.strKey){
        keyObj.strKey.forEach(function(key){
            sendData[key] = data[key] ? data[key].toString() : "";
        });
    }
    if(keyObj.numKey){
        keyObj.numKey.forEach(function(key){
            if(data[key] && isNaN(data[key])){
                console.log(key+"需要是个数字");
                sendData[key] = 0;
            }else{
                sendData[key] = data[key] ? parseInt(data[key]) : 0;
            }
        });
    }
    if(keyObj.numArrayKey){
        keyObj.numArrayKey.forEach(function(key){
            sendData[key] = [];
            if(data[key]){
                data[key].forEach(function(num){
                    sendData[key].push(parseInt(num));
                });
            } else {
                data[key] = [];
            }
        });
    }
    if(keyObj.defaultKey){
        keyObj.defaultKey.forEach(function(key){
            sendData[key] = data[key];
        });
    }
    return sendData;
}