<template>
  <modal-form-item v-show="!hide" :label="label" :required="required">
    <template v-if="!hide">
      <template v-if="$slots.default || $slots[keys]">
        <slot></slot>
        <slot :name="keys"></slot>
      </template>
      <template v-else>
        <md-field :class="{'md-error':check && error}"  v-if="type!=='radio'">
          <!-- 输入栏 -->
          <md-input v-if="type==='input'" :style="{'width':'100%'}" :disabled="disabled" v-model="childValue" :placeholder="placeholder"/>
          <!-- 选择栏 -->
          <md-select v-if="type==='select'" :placeholder="placeholder" :disabled="disabled" v-model="childValue">
            <md-option v-for="item in options" :key="item.id" :value="item.id">{{item.name}}</md-option>
          </md-select>
          <!-- 富文本 -->
          <md-textarea v-if="type==='textarea'" :disabled="disabled" v-model="childValue"/>
          <!-- chips -->
          <md-chips v-if="type==='chips'" v-model="childValue" class="no-top" :md-placeholder="placeholder"/>

          <!-- 错误ICON -->
          <md-icon v-if="check && error">clear</md-icon>
        </md-field>
        <!-- 单选栏 -->
        <div v-if="type==='radio'">
          <md-radio v-for="item in options" :disabled="disabled" :key="item.id" v-model="childValue" :value="item.id">{{item.name}}</md-radio>
          <!-- 提示 -->
          <md-icon v-if="help" :style="{'font-size':'20px !important','display':'inline-block'}">
            help
            <md-tooltip md-direction="right">{{help}}</md-tooltip>
          </md-icon>
        </div>
      </template>
    </template>
  </modal-form-item>
</template>
<script>
export default {
  name: "common-form-item",
  props:{
    help:{
      type: String,
    default:"",
    },
    keys:{
      type:String,
      default:"",
    },
    check:{ // 是否检查 this.error
      type: Boolean,
      default : false,
    },
    error:{ // 是否错误(标识)
      type: Boolean,
      default : false,
    },
    label:{ // Label
      type: String,
      default : "",
    },
    required:{ // 必填(标识)
      type: Boolean,
      default : false,
    },
    hide:{ // 是否显示
      type: Boolean,
      default : false,
    },
    type : { // 该表单组件类型
      type : String,
    default: "input",
    },
    disabled:{ //是否可修改
      type: Boolean,
      default: false,
    },
    placeholder:{ // 提示
      type: String,
      default : "",
    },
    options: { // 选择框中的选项 {value, name}
      type: Array,
      default: () => [],
    },
    value : null,
  },
  data(){
    return {
      childValue: this.value,
    }
  },
  watch:{
    value: {
      handler(newValue){
        if(newValue !== this.childValue){
          this.childValue = newValue
        }
      },
      deep: true,
      immediate: true,
    },
    childValue(newValue){
      this.$emit('input',newValue)
    },
  }
  // components: {
  //   Modal,
  // },

}
</script>