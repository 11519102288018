<template>
  <div class="diy-login-box">
    <div class="left-box">
      <img src="/img/w_logo.png" width="50">
      <h3>增值服务套餐激活</h3>
    </div>
    <md-card class="md-card-login" :class="{ 'md-card-hidden': cardHidden }" :style="{'display':'block'}">
      <md-card-content :style="{'overflow':overflowhidden ?'hidden' :'auto'}">
        <slot name="description"></slot>
        <slot name="inputs"></slot>
      </md-card-content>

      <md-card-actions>
        <slot name="footer"></slot>
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
export default {
  name: "cdk-card",
  props: {
    overflowhidden:{
      type: Boolean,
      default: false,
    },
    headerColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cardHidden: true,
    };
  },
  beforeMount() {
    setTimeout(this.showCard, 400);
  },
  methods: {
    showCard: function () {
      this.cardHidden = false;
    },
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + "";
    },
  },
};
</script>

<style lang="css" scoped>
  .md-card-login{
    border-radius: 0;
    box-shadow: none;
    max-width: none !important;
    justify-content: center;
    padding-bottom: 20px;
  }
  h3{
    font-weight: 600;
  }
  .diy-login-box{
    display: flex;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    border-radius: 6px;
    overflow: hidden;
    max-width: 900px;
    height    : unset;
    max-height: unset;
    min-height: unset;
  }
  .left-box p{
    margin: 0 0 14px 0;
    font-size: 18px;
  }
  .left-box{
    min-width: 260px;
    color: #ffffff;
    /*color: #000000;*/
    display: flex;
    font-size: 14px;
    background-color: #00bcd4;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 32px;
  }
  @media screen and (max-width: 600px){
    .diy-login-box{
      flex-direction: column;
    }
    .left-box {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
</style>