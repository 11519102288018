<template>
  <md-button @click="$emit('click')" @click.stop class="md-just-icon md-round" :class="{
    'left-icon' : !noLeft , 
    'md-info' : !color || color === 'md-info',
    'md-danger' : color === 'md-danger',
  }">
    <md-icon>{{icon}}</md-icon>
  </md-button>
</template>
<script>
export default {
  name: "table-button",
  props: {
    noLeft:{
      type: Boolean,
      default: false,
    },
    icon:{
      type: String,
      default: "none",
    },
    color: {
      type: String,
      default: "",
      validator: s => ['md-info', 'md-danger'].includes(s),
    }
  },
};
</script>
<style lang="scss" scoped>
  .left-icon{
    margin-right: 20px !important;
  }
</style>