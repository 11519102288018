import { render, staticRenderFns } from "./LiveCard.vue?vue&type=template&id=54b5d803&scoped=true&"
import script from "./LiveCard.vue?vue&type=script&lang=js&"
export * from "./LiveCard.vue?vue&type=script&lang=js&"
import style0 from "./LiveCard.vue?vue&type=style&index=0&id=54b5d803&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b5d803",
  null
  
)

export default component.exports