<template>
  <transition name="modal">
    <div class="modal-mask" :class="{'rigth-modal': type==='rightModal', 'big-modal': bigModal, 'mid-modal': midModal, 'modal-loading': loading}" ref="modal">
      <!--  -->
      <div class="modal-wrapper" @mousedown="canClose" @click="closeModal" >
        <div v-if="loading" class="modal-load">
          <slot name="loading"></slot>
        </div>
        <div v-else class="modal-container" @mousedown.stop @mouseup.stop @mousedown="noClose" @mouseup="noClose">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body text-center">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal",
  props: {
    loading:{
      type : Boolean,
      default: false,
    },
    type:{
      type: String,
      default: "",
    },
    bigModal : {
      type : Boolean,
      default: false,
    },
    midModal :{
      type : Boolean,
      default: false,
    }
  },
  data : function() {
    return {
      can_close : false,
    }
  },  
  methods: {
    noClose: function() {
      this.can_close = false;
    },
    canClose: function() {
      this.can_close = true;
    },
    closeModal: function (item) {
      if(!this.can_close){
        return ;
      }
      if(!!item.target.getAttribute("class") && item.target.getAttribute("class").includes("modal-wrapper")){
        this.$emit("close");
      }
    },
  },
  mounted(){
    if(navigator.userAgent.indexOf("Firefox")>0){ 
        this.$refs.modal.addEventListener('DOMMouseScroll',function(e){ 
            e.preventDefault(); 
        },false);  
    }else{ 
        this.$refs.modal.onmousewheel = function(e){    
            e.stopPropagation();
        }; 
    }
  },

};
</script>

<style lang="scss">
  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */
  .modal-loading{
    background: none !important;
  }
  .modal-load{
    text-align: center;
  }
  .modal-mask{
     // z-index: 8 !important;
  }
  @media screen and (max-width:800px){
   .rigth-modal .modal-container{
     margin: 0px;
   }
  }
  .big-modal  .modal-container{
    max-width : 1100px !important;
  }
  .mid-modal  .modal-container{
    max-width : 660px !important;
  }
  .rigth-modal .modal-container{
    max-width: 700px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100% !important;
    overflow-y:auto;
  }
  .rigth-modal .modal-footer{
    border-top: 1px solid #d2d2d2;
    padding-top: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .rigth-modal .modal-body{
    max-height: calc(100% - 130px);
    overflow-y:auto;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

</style>
