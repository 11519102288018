import TablePage from "./TablePage.vue";
import TableButton from "./TableButton.vue";
import ModalFormItem from "./ModalFormItem.vue";
import ModalHead from "./ModalHead.vue";
import SimpleModal from "./SimpleModal.vue";
// import SimpleModalDelete from "./SimpleModalDelete.vue";
import CardHead from "./CardHead.vue";
import AddButton from "./AddButton.vue";
import CommonFormItem from "./CommonFormItem.vue";

let CommonComponents = Vue => {
  Vue.component(TablePage.name, TablePage)
  Vue.component(CardHead.name, CardHead)
  Vue.component(ModalHead.name, ModalHead)
  Vue.component(SimpleModal.name, SimpleModal)
  // Vue.component(SimpleModalDelete.name, SimpleModalDelete)
  Vue.component(TableButton.name, TableButton)
  Vue.component(ModalFormItem.name, ModalFormItem)
  Vue.component(CommonFormItem.name, CommonFormItem)
  Vue.component(AddButton.name, AddButton)
}

export default CommonComponents
