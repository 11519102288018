<!-- 添加按钮 -->
<template>
  <md-button @click="clickFunc" class="md-primary md-info">
    <div style="margin: 0 -8px 0 -18px; display: flex;line-height: 18px;">
      <md-icon>add</md-icon><slot></slot>
    </div>
  </md-button>
</template>
<script>
export default {
  name: "add-button",
  methods:{
    clickFunc(e){
      this.$emit("click", e);
    },
  },
};
</script>