import { dataToSendData } from "@/common/interface_api/check_send.js";
export default function(api){
    api.__createApi(["getServerList", "DIS-查询服务器列表"], "/discovery/srv/query", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["sys","env", "region", "zone","appid", "net_addr"],
            numKey : ["state","type","page_no","page_size","svr_state","master"],
            numArrayKey : []
        });
        return sendData
    });
    api.__createApi(["authServer", "DIS-设置服务器"], "/discovery/srv/set" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["sys" ,"env","region" ,"zone" , "name", "grpc_addr", "http_addr"],
            numKey : ["id","state", "type","lic_num","max_load","master"],
        });
        sendData.utd_addrs = data.utd_addrs
        return sendData
    });
    api.__createApi(["addInfoEmail", "DIS-添加Email信息"], "/discovery/srv/email/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["sys", "link"],
            numKey : ["state", "link_type"],
        });
        return sendData
    })
    api.__createApi(["editInfoEmail", "DIS-修改Email信息"], "/discovery/srv/email/edit", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["sys", "link"],
            numKey : ["id", "state", "link_type"],
        });
        return sendData
    })
    api.__createApi(["delInfoEmail", "DIS-删除Email信息"], "/discovery/srv/email/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
        return sendData
    })
    api.__createApi(["queryInfoEmail", "DIS-修改Email信息"], "/discovery/srv/email/query", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["page_size", "page_no"],
        });
        return sendData
    })
    api.__createApi(["getSerZoneBind", "DIS-获取服务区域"], "/discovery/srv/zone/get", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["sys"],
            numKey : ["type", "page_no", "page_size"],
        });
        return sendData
    }, undefined, "1.0.2")
    api.__createApi(["addSerZoneBind", "DIS-添加服务区域"], "/discovery/srv/zone/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["sys", "src_area", "dest_area", "remark"],
            numKey : ["src_type", "dest_type", "state", "type"],
        });
        return sendData
    })
    api.__createApi(["editSerZoneBind", "DIS-修改服务区域"], "/discovery/srv/zone/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["sys", "src_area", "dest_area", "remark"],
            numKey : ["src_type", "dest_type", "state","id"],
        });
        return sendData
    })
    api.__createApi(["delSerZoneBind", "DIS-删除服务区域"], "/discovery/srv/zone/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["src_area"],
            numKey : ["src_type","id"],
        });
        return sendData
    })
    api.__createApi(["delService", "DIS-删除服务"], "/discovery/srv/delete" ,function(data){
        return dataToSendData(data, {
            strKey : ["link_id"],
            numKey : [ "type"],
        });
    })
    api.__createBearerApi(["addIpServer", "DIS-添加IP服务"], "/v2/discovery/srvip/add" ,function(data){
        return dataToSendData(data, {
            strKey : ["ip", "local_ip"],
            numKey : ["comp_id", "erate"],
        });
    })
    api.__createBearerApi(["editIpServer", "DIS-修改IP服务"], "/v2/discovery/srvip/set" ,function(data){
        return dataToSendData(data, {
            strKey : ["ip", "local_ip"],
            numKey : ["id", "comp_id", "erate"],
        });
    })
    api.__createBearerApi(["delIpServer", "DIS-删除IP服务"], "/v2/discovery/srvip/del" ,function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
    })
    api.__createBearerApi(["getIpServer", "DIS-查询IP服务"], "/v2/discovery/srvip/list" ,function(data){
        return dataToSendData(data, {
            strKey : ["ip"],
            numKey : ["page_size", "page_no"],
        });
    })
}